




























import { Component } from 'vue-property-decorator'

// components
import CoursePaymentModal from '@/components/modals/courses/CoursePaymentModal.vue'
import ItemInformation from '@/views/master/store/ItemInformation.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import AuthModule from '@/store/modules/auth'
import MasterCoursesModule from '@/store/modules/master/courses'
import {
  GroupType,
  GroupShopResource,
} from '@/store/types'

@Component({
  components: {
    CoursePaymentModal,
    ItemInformation,
  },
})
export default class Item extends NotifyMixin {
  private get courseID() {
    return +this.$route.params.courseID
  }

  private get storeCourse() {
    return MasterCoursesModule.storeCourse
  }

  private get self() {
    return AuthModule.self
  }

  private isLoading = true
  private showCourseModal = false
  private selectTypeGroupIndex = 0

  private created () {
    if (!this.courseID) {
      this.$router.replace({ name: 'master.courses.my' })
        .catch(() => {return})
      this.notifyError('Некорректная ссылка')
    } else {
      this.fetchStoreCourse()
    }
  }

  private fetchStoreCourse() {
    MasterCoursesModule.fetchStoreCourse(this.courseID)
      .catch(this.notifyError)
      .finally(() => this.isLoading = false)
  }

  private handleBuyCourse(groupType: GroupType) {
    this.selectTypeGroupIndex = this.storeCourse?.groups?.findIndex((group: GroupShopResource) => group.type.value === groupType) || 0
    this.showCourseModal = true
    if (this.courseID === 346) {
      try {
        this.$metrika.reachGoal('basemathpay')
      } catch {
        // eslint-disable-next-line
        console.error('Отправка метрики заблокирована расширением браузера')
      }
    }
  }
}
